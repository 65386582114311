import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/minimal-layout';
export const _frontmatter = {
  "title": "Confirm your newsletter subscription",
  "skipNewsletter": true
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Thanks for signing up!`}</h1>
    <p>{`There's one last step.`}</p>
    <p>{`Please check your inbox for an email that just got sent. You'll need to click the confirmation
link to receive any further emails.`}</p>
    <p>{`If you don't see the email after a few minutes, you might check your spam folder or other filters
and add `}<a parentName="p" {...{
        "href": "mailto:john@johno.com"
      }}>{`john@johno.com`}</a>{` to your contacts.`}</p>
    <br />
    <p><em parentName="p">{`Thanks,`}</em><br parentName="p"></br>{`
`}<em parentName="p">{`Blocks team`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      